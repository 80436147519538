import LogoIcon from "icons/logo.svg?react";
import HelpCenterIcon from "icons/help-center.svg?react";
import ChatIcon from "icons/chat.svg?react";

import { LANDING_PAGES } from "constants/pages";

import classes from "./styles/classes.module.scss";

const Footer = () => (
  <footer className={classes.wrapper}>
    <div className={classes.contentWrapper}>
      <div className={classes.supportWrapper}>
        <LogoIcon className={classes.logoIcon} />
        <ul className={classes.links}>
          <li>
            <a
              className={classes.link}
              href="https://coindisco.freshdesk.com/support/tickets/new"
              target="_blank"
            >
              <ChatIcon />
              <p className={classes.text}>Contact Support</p>
            </a>
          </li>
          <li>
            <a
              className={classes.link}
              href="https://coindisco.freshdesk.com/support/home"
              target="_blank"
            >
              <HelpCenterIcon />
              <p className={classes.text}>Help Center</p>
            </a>
          </li>
        </ul>
      </div>
      <div className={classes.divider} />
      <div className={classes.legalWrapper}>
        <p className={classes.copyright}>&copy; 2024 Coindisco</p>
        <ul className={classes.links}>
          <li className={classes.item}>
            <a
              className={classes.link}
              href={LANDING_PAGES.terms}
              target="_blank"
            >
              Terms and Conditions
            </a>
          </li>
          <li className={classes.item}>
            <a
              className={classes.link}
              href={LANDING_PAGES.privacy}
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
