import { FC, PropsWithChildren } from "react";
import {
  Outlet,
  useRouteError,
  isRouteErrorResponse,
  ScrollRestoration,
  Scripts,
} from "@remix-run/react";
import { LinksFunction, defer } from "@remix-run/cloudflare";
import { createPortal } from "react-dom";
import { useHydrated } from "remix-utils/use-hydrated";

import store from "store";
import { accountServerThunks } from "store/account";
import { addressesServerThunks } from "store/addresses";

import { PRODUCTION_DOMAIN } from "constants/domains";
import { IS_SERVER } from "constants/env";

import useNonce from "hooks/use-nonce";

import { createMeta } from "operations/meta";
import reduxSync from "operations/redux";
import { serverLoader } from "operations/moduleSync";

import ErrorBoundaryScreen from "components/error-boundary";
import NotFound from "components/not-found";
import Dialog from "components/dialog";

import Splash from "modules/splash";
import Footer from "modules/footer";
import FeedbackModalSync from "modules/feedback-modal-sync";
import AuthTemplate from "modules/auth-template";

import Head from "./Head";

import "styles/global.scss";

export const Layout = reduxSync(({ children }: PropsWithChildren) => {
  const isHydrated = useHydrated();
  const nonce = useNonce();
  const content = (
    <>
      <Splash />
      {children}
      <Footer />
      <Dialog />
      <ScrollRestoration nonce={nonce} />
      <Scripts nonce={nonce} />
    </>
  );

  if (IS_SERVER) {
    return (
      <html>
        <head>
          <Head />
        </head>
        <body>
          <div id="_remix">{content}</div>
        </body>
      </html>
    );
  }

  return (
    <>
      {isHydrated && createPortal(<Head />, document.head)}
      {content}
    </>
  );
});

const App = () => (
  <AuthTemplate>
    <FeedbackModalSync />
    <Outlet />
  </AuthTemplate>
);

export const loader = serverLoader(() =>
  defer({
    [accountServerThunks.loadAccountServer.typePrefix]:
      accountServerThunks.loadAccountServer(),
    [accountServerThunks.loadPaymentMethodsServer.typePrefix]:
      accountServerThunks.loadPaymentMethodsServer(),
    [addressesServerThunks.loadAddressesServer.typePrefix]:
      addressesServerThunks.loadAddressesServer(),
  }),
);

export const clientLoader = ({ serverLoader }) => {
  const accountResult = accountServerThunks.loadAccountServer.selectResult(
    store.getState(),
  );

  if (accountResult) {
    return {
      [accountServerThunks.loadAccountServer.typePrefix]: accountResult,
    };
  }

  return serverLoader();
};

export const links: LinksFunction = () => [
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: `${PRODUCTION_DOMAIN}/assets/favicon.png`,
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: `${PRODUCTION_DOMAIN}/assets/apple-touch-icon.png`,
  },
  { rel: "stylesheet", href: "/fonts/fonts.css" },
];

export const meta = createMeta(({ error }) => {
  if (isRouteErrorResponse(error)) {
    return {
      title: error.status === 404 ? "404 Not found" : "Error occurred",
    };
  }

  return {};
});

export const ErrorBoundary: FC = () => {
  const error = useRouteError();
  // eslint-disable-next-line no-console
  console.error(JSON.stringify(error));

  return isRouteErrorResponse(error) && error.status === 404 ? (
    <AuthTemplate>
      <NotFound />
    </AuthTemplate>
  ) : (
    <ErrorBoundaryScreen error={error as Error} />
  );
};

export default App;
