import { compareDesc } from "date-fns";

interface GroupedAddressArgs {
  addressIds: Address["id"][];
  addressEntities: { [id: number]: Address };
}

export const getGroupedAddresses = ({
  addressIds,
  addressEntities,
}: GroupedAddressArgs) => {
  if (!addressIds.length) {
    return [];
  }

  const networksMap: Record<Network["name"], Address["id"][]> = {};

  addressIds.forEach(addressId => {
    const address = addressEntities[addressId];
    networksMap[address.network.name] = [
      ...(networksMap[address.network.name] || []),
      address.id,
    ];
  });

  return Object.entries(networksMap)
    .map(([title, addressIds]) => ({
      options: addressIds.sort((a, b) =>
        compareDesc(
          new Date(addressEntities[a].createdAt),
          new Date(addressEntities[b].createdAt),
        ),
      ),
      title,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
};
