import joinClassNames from "classnames";
import { useEffect, useState } from "react";
import { useHydrated } from "remix-utils/use-hydrated";

import SplashLogoIcon from "icons/splash-logo.svg?react";

import globalClasses from "styles/classes.module.scss";

import classes from "./styles/classes.module.scss";

const Splash = () => {
  const [isShow, setIsShow] = useState(true);
  const isHydrated = useHydrated();

  useEffect(() => {
    if (!isShow) {
      document.body.classList.remove(globalClasses.splashOverflowHidden);
    }
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  return (
    <div
      className={joinClassNames(classes.wrapper, {
        [classes.closeWrapper]: isHydrated,
      })}
      onAnimationEnd={() => setIsShow(false)}
    >
      <div className={classes.ring}>
        <div className={classes.ringPart} />
        <div className={classes.ringPart} />
        <div className={classes.ringPart} />
        <SplashLogoIcon className={classes.logoIcon} />
      </div>
    </div>
  );
};

export default Splash;
