import { FC, ReactNode, useEffect } from "react";
import { type UnsubscribeListener } from "@reduxjs/toolkit";
import { useNavigate } from "@remix-run/react";

import { watcher } from "store";

import Header from "./components/header";

import { useConnect } from "./duck/hooks";
import { Interval } from "../../operations/intervals";
import { TimeMilliseconds } from "../../constants/time";

interface Props {
  children: ReactNode;
}

const AuthTemplate: FC<Props> = ({ children }) => {
  const { loadCurrencies, loadPrices, accountLoadScenario } = useConnect();
  const navigate = useNavigate();

  useEffect(() => {
    loadCurrencies();

    const interval = new Interval(loadPrices, {
      timeout: TimeMilliseconds.minute,
    });

    let accountUnsubscribe: UnsubscribeListener;
    accountLoadScenario().then(() => {
      accountUnsubscribe = watcher.startListening({
        predicate: (
          _action,
          { account: { data } },
          { account: { data: prevData } },
        ) => data?.email !== prevData?.email,
        effect: () => {
          navigate(
            {
              pathname: location.pathname,
              search: location.search,
            },
            {
              replace: true,
              preventScrollReset: true,
            },
          );
        },
      });
    });

    return () => {
      interval.dispose();
      accountUnsubscribe();
    };
  }, []);

  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default AuthTemplate;
